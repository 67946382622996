import React from 'react';
import styled from 'styled-components';
import trackEvent from 'gatsby-plugin-simple-analytics';

import Layout from 'src/components/layout';
import { forSize } from 'src/style-utils';
import logo from 'src/assets/logo.png';
import { H2, Text } from 'src/components/base';

const ConfirmationSection = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  height: 100vh;

  padding: 120px;

  ${forSize.phoneOnly`
    padding: 20px;
  `};
`;

const ConfirmationSubHeading = styled(H2)`
  margin-top: 12px;
  text-align: center;
`;

const ConfirmationText = styled(Text)`
  margin-bottom: 42px;
  text-align: center;

  ${forSize.phoneOnly`
    margin-bottom: 20px;
  `}
`;

const Logo = styled.img`
  width: 128px;
  height: 128px;

  ${forSize.phoneOnly`
    width: 80px;
    height: 80px;
  `};
`;

const GMAIL_LINK =
  'https://mail.google.com/mail/u/0/#search/in%3Aanywhere+subject%3A%E2%80%9DMonoFocus%3A+Confirm+your+email%E2%80%9D+from%3Abartlomiej%40monofocus.app';

export default function ConfirmationPage() {
  return (
    <Layout title="Check your inbox" withFooter={false} withHeader={false}>
      <ConfirmationSection>
        <Logo src={logo} />
        <ConfirmationSubHeading>Check your inbox</ConfirmationSubHeading>
        <ConfirmationText>
          Using Gmail?{' '}
          <a
            href={GMAIL_LINK}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => trackEvent('click_open_gmail')}
          >
            Click here
          </a>{' '}
          to go directly to your inbox.
        </ConfirmationText>
      </ConfirmationSection>
    </Layout>
  );
}
